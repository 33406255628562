import { createApp } from 'vue'
import './style.css'
import App from './App.vue'

import { router } from './router';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

const app = createApp(App)

console.log(import.meta.env)

Sentry.init({
  app,
  dsn: "https://1fb2475dbc4c432db0298361babe5e44@sentry.likecentre.ru/25",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

app.use(router);
app.mount('#app')
